import React from 'react';

interface SectionHeaderProps {
  header: string;
  superheader?: string;
}

const SectionHeader = (props: SectionHeaderProps) => {
  return (
    <div>
      {props.superheader && (
        <p className="uppercase font-bold text-gray-500 text-sm tracking-widest">
          {props.superheader}
        </p>
      )}
      <h3 className="text-3xl text-primary font-bold mt-1">{props.header}</h3>
    </div>
  );
};

export default SectionHeader;
