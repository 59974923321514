import React from 'react';
import { Link } from 'gatsby';
import { formatDate } from './../../util';

interface LinkCardProps {
  linkPath: string;
  image: string;
  title: string;
  date?: Date;
  children?: React.ReactNode;
}

const LinkCard: React.FC<LinkCardProps> = props => {
  return (
    <Link to={props.linkPath}>
      <div className="rounded-lg bg-white shadow h-auto flex flex-col sm:flex-row">
        <div className=" max-h-48  sm:w-1/4">
          <img
            className="rounded-tr-lg sm:rounded-tr-none rounded-tl-lg sm:rounded-bl-lg object-cover w-full h-48"
            src={props.image}
          />
        </div>
        <div className="p-6 pt-0 w-full sm:w-3/4">
          <p className="text-primary font-bold mt-10 text-xl sans-serif">
            {props.title}
          </p>{' '}
          {!!props.date && (
            <p className="text text-gray-500">
              {formatDate(new Date(props.date), true)}
            </p>
          )}
          <div>{props.children}</div>
        </div>
      </div>
    </Link>
  );
};

export default LinkCard;
